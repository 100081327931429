import { atom } from 'recoil';
import { localStorageEffect } from '../resources/persistState';

export const tokenState = atom({
    key: 'tokenState',
    default:'',
    effects_UNSTABLE:[
        localStorageEffect('TOKENID')
    ],
});

export const pageLoaderState = atom({
    key: 'pageLoaderState', // unique ID (with respect to other atoms/selectors)
    default: {
        page:"",
        status:false
    }, // default value (aka initial value)
});


export const examListState = atom({
    key: 'examListState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const examDetailsState = atom({
    key: 'examDetailsState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const questionState = atom({
    key: 'questionState', // unique ID (with respect to other atoms/selectors)
    default:{}, // default value (aka initial value)
});

export const activeQuestionState = atom({
    key: 'activeQuestionState', // unique ID (with respect to other atoms/selectors)
    default: 1, // default value (aka initial value)
});

export const questionCountState = atom({
    key: 'questionCountState', // unique ID (with respect to other atoms/selectors)
    default: 0, // default value (aka initial value)
});

export const qnsProgressState = atom({
    key: 'qnsProgressState', // unique ID (with respect to other atoms/selectors)
    default: 0, // default value (aka initial value)
});


export const getVendorDetailsState = atom({
    key: 'getVendorDetailsState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const getVendorSimilerState = atom({
    key: 'getVendorSimilerState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const getAllOrderState = atom({
    key: 'getAllOrderState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const getAllStateState = atom({
    key: 'getAllStateState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export const getProfileState = atom({
    key: 'getProfileState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});




