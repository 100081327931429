import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';  

import "./style.scss";
 
const options = {
    items: 2,
    loop: true,
    margin: 20,
    nav: true,
    dots: false,
    responsiveClass: true,
    autoplay:true, 
    autoplayHoverPause: true, 
    smartSpeed: 550,
    autoplayTimeout: 7000, 
    autoplaySpeed: 3000, 
    loop: true, 
    responsive: {
        0: { items: 1 },
        576: { items: 1 },
        768: { items: 2 },  
    }
};



const Prices = [
    {
        _id: 'e1', 
        exName: 'Free Trial ', 
        price: '$99',
        features: [
            { _id: 1, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 2, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 3, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 4, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 5, feature: 'Lorem ipsum dolor sit amet' },
        ]
    },
    {
        _id:'e2', 
        exName: '2 Months Plan', 
        price: '$99',
        features: [
            { _id: 1, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 2, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 3, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 4, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 5, feature: 'Lorem ipsum dolor sit amet' },
        ]
    },  
    {
        _id:'e3', 
        exName: '3 Months Plan', 
        price: '$99',
        features: [
            { _id: 1, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 2, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 3, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 4, feature: 'Lorem ipsum dolor sit amet' },
            { _id: 5, feature: 'Lorem ipsum dolor sit amet' },
        ]
    }   
]
 

const Pricing = () => {
  return (
    <>
        <div className="ptb">
            <Container>
                <h2 className="after mb-3 mb-lg-4">Pricing</h2>
                 
                <Row className='g-4'>
                    <Col xxl={8} lg={8}>
                        <OwlCarousel className='owl-theme pricingCarousel' {...options}> 
                            {
                                Prices.map((price) => { 
                                    return <div className="pricing__card position-relative text-center" key={price._id}> 
                                    <h4 className='h3 title mb-2 mb-lg-3'>{price.exName}</h4>
                                    <div className="price h4 fw-bold text-dark">
                                        Only <span>{price.price}</span>
                                    </div>
                                    <ul className='list-unstyled ps-0 fw-normal my-4'>
                                    {
                                        price.features.map((feature) => {
                                            return <li className='mb-1 mb-md-2' key={feature._id}><AiOutlineCheckCircle/> {feature.feature}</li>
                                            })
                                        }
                                    </ul>

                                    <Link to="/" className='btn btn-outline-dark fw-semibold'>BOOK SLOT</Link> 
                                </div>
                                })
                            }
                        </OwlCarousel> 
                    </Col>
                    <Col xxl={4} lg={4}>
                        <h3 className="h4 text-center">Money back guarantee</h3>
                        <div className="text-center mt-3">
                            <img src="images/money_back.webp" className='img-fluid' alt="money-back-thumbnail" />
                        </div>
                        <p className='text-justify px-lg-4 px-xxl-5 small fw-normal mt-3'>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis sed harum cupiditate ut, dolores, minima ipsam sint fugit ipsa quo nesciunt eum. Voluptatem aliquid saepe obcaecati modi ducimus laboriosam a repudiandae, facilis in doloribus, voluptate quidem! Libero architecto et sint quisquam similique natus? Saepe.
                        </p>
                    </Col>
                </Row>

            </Container>    
        </div>   
    </>
  )
}

export default Pricing;
