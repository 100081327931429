import React from 'react'
import Footer from '../../../component/Footer';
import Header from '../../../component/Header';
import PageHeader from '../../../component/PageHeader';
import Pricing from '../../../component/Pricing';
import Testimonials from '../../../component/Testimonials';

const PricingPage = () => {
  return (
    <>
      <Header />
      <PageHeader 
        title="Pricing"
        image="images/contact_page_header.webp" 
      />
      <Pricing/>
      <Testimonials/>
      <Footer/>
    </>
  )
}

export default PricingPage;
