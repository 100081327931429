
import { setRecoil } from "recoil-nexus";
import { activeQuestionState, examDetailsState, examListState, getAllOrderState, getAllStateState, getAllVendorState, getProfileState, questionCountState, questionState } from "../RecoilState";
import axios from "../resources/axios";


export const examListAction = async (authToken) => {
   
    axios({
        method: 'get',
        url: `test-list`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${authToken}`
        },
        
    })
    .then(({data})=>{
        
        if(data.status == "true"){
            //alert(JSON.stringify(data.data))
            setRecoil(examListState,data?.data)
           
        }else{
            //setMsg({status:true,color:'red',msg:data.message})
        }
      //  setTimeout(()=>setMsg({status:false,color:'',msg:''}),5000)
    }).catch((error) => {
        console.log(error)
       
    })
}

export const examDetailsAction = async (authToken,slug) => {
   
    axios({
        method: 'get',
        url: `test-details/${slug}`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${authToken}`
        },
        
    })
    .then(({data})=>{
        
        if(data.status == "true"){
            setRecoil(examDetailsState,data?.data)
            setRecoil(questionCountState,data?.data?.test_question_count)
        }else{
            //setMsg({status:true,color:'red',msg:data.message})
        }
      //  setTimeout(()=>setMsg({status:false,color:'',msg:''}),5000)
    }).catch((error) => {
        console.log(error)
       
    })
}

export const answerSaveAction = async (values, actions, authToken,activeQuestion,navigate,slug) => {
   
    axios({
        method: 'post',
        url: `answer-save`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization':`Bearer ${authToken}`
        },
        data:values
        
    })
    .then(({data})=>{   
        if(data.status == "true"){
            setRecoil(activeQuestionState,parseFloat(activeQuestion+1))
            if(data.data == 'end_exam'){
                navigate(`/exam/result?slug=${slug}`)
            }
        }else{
            //setMsg({status:true,color:'red',msg:data.message})
        }
        actions.setSubmitting(false)
    }).catch((error) => {
        actions.setSubmitting(false)
       
    })
}

