import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { AiOutlineUser, AiOutlineLogout } from 'react-icons/ai';
import { IoClipboardOutline } from 'react-icons/io5';
import "./style.scss";
 

const Navigation = () => {
  // const scroll = useScrollHandler();  {{`scroll ? "white" : "transparent"`}}
  const [header, setHeader] = useState("nav")

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("nav")
    } else if (window.scrollY > 70) {
      return setHeader("stickyNav")
    } 
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  
  return (
    <div>
        <Navbar expand="lg" className={header} id="innerNav">
            <Container>
                <Link to='/dashboard' className='navbar-brand dashboard'><img src="../images/logo.webp" alt="logo" /></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto align-items-center">
                    <Link className='nav-link' to="/exam/dashboard">Home</Link> 
                    <Link className='nav-link' to="/exam/list">Exams</Link> 
                    <Link className='nav-link' to="/pricing">Pricing</Link> 
                    <Dropdown>
                        <Dropdown.Toggle className='btn profile__btn p-0' id="dropdown-basic">
                            {/* <FaUserCircle /> */}
                            <img src="../images/user.png" alt="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Link to='/profile' className='dropdown-item'><AiOutlineUser/> Profile</Link>
                            <Link to='/exam/list' className='dropdown-item'><IoClipboardOutline/> Exam list</Link> 
                            <Link to='/login' className='dropdown-item'><AiOutlineLogout/> Log Out</Link> 
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  )
}

export default Navigation;
