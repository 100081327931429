import React, { useState } from 'react';
 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';


// import './style.scss';
import Filter from './Filter';
import QuestionData from './QuestionData';
import QuestionModal from '../QuestionModal';

const DetailedResult = () => {
    const [qestionModal, setQestionModal] = useState(false); 
    const questionDetailClose = () => setQestionModal(false);
    const questionDetailShow = () => setQestionModal(true);
  return (
    <>
        <Row className='g-4 detailed__result__wrapper'>
            <Col className='filter__wrapp'>
                <Filter/>
            </Col>    
            <Col className='filter__content'>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Question No</th>
                            <th>Your Answer</th>
                            <th>Correct Answer</th>
                            <th>Result</th>
                            <th>Domain Name</th>
                            <th>Knowledge Area</th>
                            <th>Time Taken</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {
                            QuestionData.datalist.map((data) => {
                                // const mapResult = (data.result);
                                // console.log(mapResult);
                                return <tr key={data._id}>
                                    <td>
                                        <Button className='question__btn' onClick={questionDetailShow}>Q:{data.qNo}</Button>
                                    </td>
                                    <td>{data.yourAns}</td>
                                    <td>{data.correctAns}</td>
                                    <td>
                                        { data.result == 'fail' ? <AiFillCloseCircle className='fail'/> : <AiFillCheckCircle className='pass'/> }
                                    </td>
                                    <td>{data.domainName}</td>
                                    <td>{data.knowledgeArea}</td>
                                    <td>{data.timeTaken}</td>
                                </tr>
                            })
                        }
                        {/* <tr>
                            <td>
                                <Button className='question__btn' onClick={questionDetailShow}>Q:1</Button>
                            </td>
                            <td>A</td>
                            <td>C</td>
                            <td><AiFillCloseCircle className='fail'/></td>
                            <td>1. People</td>
                            <td>Resource Management</td>
                            <td>0:45</td>
                        </tr>
                        <tr>
                            <td>
                                <Button className='question__btn' onClick={questionDetailShow}>Q:2</Button>
                            </td>
                            <td>A</td>
                            <td>C</td>
                            <td><AiFillCheckCircle className='pass'/></td>
                            <td>1. People</td>
                            <td>Resource Management</td>
                            <td>0:45</td>
                        </tr> */}
                    </tbody>
                </table>
            </Col>     
        </Row>   


        {/* ----------- Question Details Modal Content Here ------------------ */}
        <QuestionModal modalName={qestionModal} close={questionDetailClose} />

    </>
  )
}

export default DetailedResult;
