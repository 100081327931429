import React, { useEffect, useState } from 'react';
 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

import "./style.scss";

  
const Header = () => { 
  const [header, setHeader] = useState("nav")

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("nav")
    } else if (window.scrollY > 70) {
      return setHeader("stickyNav")
    } 
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  return (
    <div>
        <Navbar expand="lg" className={header}>
            <Container>
                <Link to='/' className='navbar-brand text-white'>
                  <img src="images/logo.webp" alt="logo" />
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Link className='nav-link' to="/">Home</Link>
                    <Link className='nav-link' to="/about">About</Link> 
                    <Link className='nav-link' to="/exam/list">Exams</Link> 
                    <Link className='nav-link' to="/pricing">Pricing</Link>
                    <Link className='nav-link' to="/contact">Contact</Link>
                    <Link className='nav-link login' to="/login">Login</Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  )
}

export default Header;
