import React, { useState } from 'react'
import Navigation from './../../../component/Navigation/index';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'; 
import FormCheck from 'react-bootstrap/FormCheck'; 
import { GiCheckMark } from 'react-icons/gi';

import Data from './Data';
import './style.scss';
import QuestionDetailsModal from './QuestionDetailsModal/index';

const QuestionGrid = () => {
    const [qestionDetailsModal, setQestionDetailsModal] = useState(false);
    const questionDetailsClose = () => setQestionDetailsModal(false);
    const questionDetailsShow = () => setQestionDetailsModal(true);

    return (
        <>
            <Navigation />

            <div className="py-5 dashboard__wraper">
                <Container>
                    <Card className=''>
                        <Card.Body className='p-4'>
                            <h3>Examination Title Name</h3>
                            <div className="d-flex flex-wrap justify-content-between border-bottom mt-3 mb-3">
                                <p className='mb-1'>Displaying 50 Questions</p>
                                <p className='lead fw-semibold text-primary mb-0'>01:04:00</p>
                            </div>

                            {/* ---- Filter Wrapper -------- */}
                            <Row className='g-4'>
                                <Col sm={6}>
                                    <Card className='shadow-sm d-inline-flex bg-gradient'> 
                                        <Card.Body className='pe-5'> 
                                            <h5 className='mb-3'>Filter</h5>
                                            <Form.Check type='checkbox' id="all">
                                                <FormCheck.Input type='checkbox' />
                                                <FormCheck.Label>All</FormCheck.Label>
                                            </Form.Check>
                                            <Form.Check type='checkbox' id="answered">
                                                <FormCheck.Input type='checkbox' />
                                                <FormCheck.Label>Answered</FormCheck.Label>
                                            </Form.Check>
                                            <Form.Check type='checkbox' id="correctly">
                                                <FormCheck.Input type='checkbox' />
                                                <FormCheck.Label>Unanswered</FormCheck.Label>
                                            </Form.Check>
                                            <Form.Check type='checkbox' id="marked">
                                                <FormCheck.Input type='checkbox' />
                                                <FormCheck.Label>Marked</FormCheck.Label>
                                            </Form.Check> 
                                        </Card.Body>  
                                    </Card>
                                </Col> 
                                <Col sm={6} className="d-flex justify-content-sm-end">
                                    <Card className='shadow-sm d-inline-flex bg-gradient'> 
                                        <Card.Body className='pe-5'> 
                                            <h5>Legend:</h5>
                                            <ul className="list-unstyled ps-0 mb-0 mt-3 legend__list">
                                                <li>
                                                    <div className="que__item marked">
                                                        <Button>Q : X</Button> 
                                                    </div>
                                                    <div>-Unanswered question</div>
                                                </li>
                                                <li>
                                                    <div className="que__item marked">
                                                        <Button>Q : X</Button> 
                                                        <span className='marked'>
                                                            <GiCheckMark />
                                                            <span>Marked</span>
                                                        </span>
                                                    </div>
                                                    <div>-Unanswered question</div>
                                                </li>
                                                <li>
                                                    <div className="que__item marked">
                                                        <Button>Q : X</Button> 
                                                        <span className='fw-semibold lead'>A</span>
                                                        <span className='marked'>
                                                            <GiCheckMark />
                                                            <span>Marked</span>
                                                        </span>
                                                    </div>
                                                    <div>-Unanswered question</div>
                                                </li>
                                                <li>
                                                    <div className="que__item marked">
                                                        <Button>Q : X</Button> 
                                                        <span className='fw-semibold lead'>A</span> 
                                                    </div>
                                                    <div>-Answered question</div>
                                                </li>
                                            </ul>
                                        </Card.Body>  
                                    </Card>
                                </Col> 
                            </Row>

                            <div className="question__list">
                                <Row className='g-2'>
                                    {
                                        Data.questions.map((que) => {
                                            return <Col lg={2} md={3} key={que.id}>
                                                {
                                                    que.marked == 'Yes'
                                                        ?
                                                        <div className="que__item marked">
                                                            <Button onClick={questionDetailsShow}>Q : {que.id}</Button>
                                                            <span className='fw-semibold lead'>{que.ans}</span>
                                                            <span className='marked'>
                                                                <GiCheckMark />
                                                                <span>Marked</span>
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className="que__item">
                                                            <Button onClick={questionDetailsShow}>Q : {que.id}</Button>
                                                            <span className='fw-semibold lead'>{que.ans}</span>
                                                        </div>
                                                }

                                            </Col>
                                        })
                                    }

                                </Row>
                            </div>
                        </Card.Body>
                    </Card>

                </Container>
            </div>

            {/* ----------- Question Details Modal Content Here ------------------ */}
            <QuestionDetailsModal modalName={qestionDetailsModal} close={questionDetailsClose} />



        </>
    )
}
export default QuestionGrid;