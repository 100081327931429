import React, { useState } from 'react'
import Navigation from '../../../component/Navigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './style.scss';

const Profile = () => {
    const [passModal, setPassModal] = useState(false);

    const resetPassModalClose = () => setPassModal(false);
    const resetPasswordModalShow = () => setPassModal(true);
    return (
        <div>
            <Navigation />
            <div className="py-5">
                <Container>
                    <Row className='g-4 justify-content-center'>
                        <Col md={6}>
                            <Card className='shadow-sm rounded profile__box'>
                                <Card.Header className='bg-white px-4 py-3'>My Profile</Card.Header>
                                <Card.Body className='p-4'>
                                    <div className="d-flex">
                                        <div className="left__box">
                                            <img src="images/user.webp" className='img-fluid rounded-circle' alt="thumbnail" />
                                            <h5 className="fw-normal mt-2 mb-0">Shuvrodeb Roy</h5>
                                        </div>
                                        <div className="right__box">
                                            <ul className='list-unstyled ps-0 mb-0'>
                                                <li>
                                                    <div className="text-uppercase fs-13 text-muted">mail</div>
                                                    <div className="">shuvro@gmail.com</div>
                                                </li>
                                                <li>
                                                    <div className="text-uppercase fs-13 text-muted">current plan</div>
                                                    <div className="fw-semibold">Free</div>
                                                </li>
                                                <li>
                                                    <div className="text-uppercase fs-13 text-muted">subscription date</div>
                                                    <div className="fw-semibold">04 / 04 / 2022</div>
                                                </li>
                                                <li>
                                                    {/* <div className="text-uppercase fs-13 text-muted">subscription date</div>  */}
                                                    <Button onClick={resetPasswordModalShow} variant='outline-primary' className="fw-semibold">Change Password</Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>


            <Modal show={passModal} onHide={resetPassModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-4" controlId="prevPass">
                            <Form.Label className='mb-1'>Previous Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter Previous Password" className='form-control-lg fs-6' />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="newPass">
                            <Form.Label className='mb-1'>New Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter New Password" className='form-control-lg fs-6' />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={resetPassModalClose}>
                        Go Back
                    </Button>
                    <Button variant="danger" onClick={resetPassModalClose}>
                        Reset
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Profile;
