import React from 'react';
import Container from 'react-bootstrap/Container';
import Footer from '../../../component/Footer';
import Header from '../../../component/Header';
import PageHeader from '../../../component/PageHeader';
import Testimonials from '../../../component/Testimonials';

 

const About = () => {
  return (
    <>
      <Header />
      <PageHeader 
        image="images/page_header.webp" 
        title="About Us"
      />

      <div className="ptb bg-white">
        <Container>
          <h2 className='text-center fw-normal'>Our Mission</h2>
          <p className='text-center lead-sm'>Lorem ipsum dolor sit amet.</p>

          <div className="col-lg-9 mx-auto text-justify">
            <h2 className='text-center fw-normal'>About</h2>
            <p className='text-grey lead-sm'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolor magnam suscipit consectetur voluptatibus et cupiditate quas esse fugit minus quam eveniet saepe aliquam id, dolore dolorum quasi alias cumque provident assumenda eos. Nesciunt quae numquam illum, facilis pariatur voluptatibus nisi beatae placeat. Adipisci aliquam, ratione error eum quas maxime perferendis id officiis odit optio?
            </p>
            <p className='text-grey lead-sm'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolor magnam suscipit consectetur voluptatibus et cupiditate quas esse fugit minus quam eveniet saepe aliquam id, dolore dolorum quasi alias cumque provident assumenda eos. Nesciunt quae numquam illum, facilis pariatur voluptatibus nisi beatae placeat. Adipisci aliquam, ratione error eum quas maxime perferendis id officiis odit optio?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolor magnam suscipit consectetur voluptatibus et cupiditate quas esse fugit minus quam eveniet saepe aliquam id, dolore dolorum quasi alias cumque provident assumenda eos. Nesciunt quae numquam illum, facilis pariatur voluptatibus nisi beatae placeat. Adipisci aliquam, ratione error eum quas maxime perferendis id officiis odit optio?
            </p>
            <p className='text-grey lead-sm'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolor magnam suscipit consectetur voluptatibus et cupiditate quas esse fugit minus quam eveniet saepe aliquam id, dolore dolorum quasi alias cumque provident assumenda eos. Nesciunt quae numquam illum, facilis pariatur voluptatibus nisi beatae placeat. Adipisci aliquam, ratione error eum quas maxime perferendis id officiis odit optio?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolor magnam suscipit consectetur voluptatibus et cupiditate quas esse fugit minus quam eveniet saepe aliquam id, dolore dolorum quasi alias cumque provident assumenda eos. Nesciunt quae numquam illum, facilis pariatur voluptatibus nisi beatae placeat. Adipisci aliquam, ratione error eum quas maxime perferendis id officiis odit optio?
            </p>

          </div>


        </Container>
      </div>


      <Testimonials />
      <Footer/>

    </>
  )
}

export default About;