import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import { AiFillCloseCircle } from 'react-icons/ai';

import './style.scss';

const QuestionDetailsModal = ({modalName, close }) => {
    const [selected, setSelected] = useState('yes');

    const handleChange = event => {
        console.log(event.target.value);
        setSelected(event.target.value);
    };
    return (
        <>
            <Modal show={modalName} onHide={close} className="question__details__modal">
                <Modal.Header closeButton>
                    <Modal.Title>Question Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='position-relative'>
                    <div className="small position-absolute bg-light px-1 rounded" style={{color:'#aaa',right:'1rem',top:'0.25rem'}}>Question ID: 003456</div>
                    <div className="text-dark fw-semibold">Question No: 01</div>

                    <div className="bg-light p-2 border rounded my-3 fs-6" >
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur perspiciatis, quidem optio obcaecati quasi expedita perferendis possimus nemo eaque ea, aliquam vero!
                    </div>

                    <Form className='form__wrapp ps-3'>
                        <Form.Check type='radio' id="ans1">
                            <span className='fw-semibold'>A.</span>
                            <FormCheck.Input type='radio' name='answer' 
                                checked={selected === 'no'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>The Project manager</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='radio' id="ans2">
                            <span className='fw-semibold'>B.</span>
                            <FormCheck.Input type='radio' name='answer'  
                                checked={selected === 'no'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>The developement team</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='radio' id="ans3">
                            <span className='fw-semibold'>C.</span>
                            <FormCheck.Input type='radio' name='answer' 
                                checked={selected === 'yes'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>The scrum master</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='radio' id="ans4">
                            <span className='fw-semibold'>D.</span>
                            <FormCheck.Input type='radio' name='answer' 
                                checked={selected === 'no'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>the product owner</FormCheck.Label>
                        </Form.Check> 
                    </Form>
                     

                    <div className="bg-light p-3 border rounded mt-3 mt-lg-4">
                        <h5 className=''>Hint</h5>
                        <p className='mb-2'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati at officiis vero quisquam perspiciatis totam?
                        </p> 

                        <ul className='ps-3 mb-0'>
                            <li className='mb-1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam deleniti iure ab animi praesentium tempore?</li>
                            <li className='mb-1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam deleniti iure ab animi praesentium tempore?</li>
                            <li className='mb-1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam deleniti iure ab animi praesentium tempore?</li>
                        </ul>

                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={close}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default QuestionDetailsModal;
