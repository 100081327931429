import React from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import Button from 'react-bootstrap/Button';
import Header from '../../../component/Header';

import './style.scss';
import { Link } from 'react-router-dom';
import Footer from '../../../component/Footer';
import PageHeader from './../../../component/PageHeader/index';

import * as yup from 'yup';
import { Formik } from "formik";
import { loginAction } from '../../../utilit/RecoilAction/AuthAction';
import { useRecoilValue } from 'recoil';
import { tokenState } from '../../../utilit/RecoilState/HomeState';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Spinner } from 'react-bootstrap';

const Login = () => {

    const token = useRecoilValue(tokenState)
    const alert = useAlert(); 
    let navigate  = useNavigate();
    
    let loginValidate = yup.object().shape({
        email: yup.string()
            .required('Plase enter your email')
            .email('Plase enter your valid email'),
        password: yup.string()
            .required('Plase enter your password'),

    });

    return (
        <>
            <Header />
            <PageHeader
                image="images/login__page__header.webp"
                title="Log In"
            />

            <div className="ptb">
                <Container>
                    <div className="auth__wrapp p-4 p-xl-5 pt-xl-4 mx-auto">
                        <h2 className="text-center mb-4 mb-xl-5">Log In</h2>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            enableReinitialize={true}
                            validationSchema={loginValidate}
                            onSubmit={(values, actions) => {
                               loginAction(values,actions,navigate,alert)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-4" controlId="emailAddress">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control onChange={(e)=>{setFieldValue('email',e.target.value)}} name='email' type="email" placeholder="Enter email address" />
                                        {errors.email && touched.email && <p style={{ color: "red" }}>{errors.email}</p>}
                                        
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control onChange={(e)=>{setFieldValue('password',e.target.value)}} name='password' type="password" placeholder="Enter password" />
                                        {errors.password && touched.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                    </Form.Group>

                                    <Form.Group className="mb-4 d-flex justify-content-between">
                                        <Form.Check type='checkbox' id="rememberMe">
                                            <FormCheck.Input type='checkbox' />
                                            <FormCheck.Label>Remember Me</FormCheck.Label>
                                        </Form.Check>
                                        <Link to='/forgot-password' className='forpass'>Forgot Password?</Link>
                                    </Form.Group>


                                    <Form.Group className="mt-5 text-center">
                                        <Button type='submit' varient="primary" disabled={isSubmitting ? true : false} className='w-75 btn-lg fw-semibold'>{isSubmitting ? 'Loading...': 'LOGIN'}</Button>
                                        {/* <Link to='/dashboard' className='w-75 btn btn-primary btn-lg fw-semibold'>LOGIN</Link> */}
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                        <div className="text-center mt-4">
                            <span>Haven't any account? </span>
                            <Link to='/signup' className='link ps-2 fw-medium'>Sign up</Link>
                        </div>

                    </div>
                </Container>
            </div>

            <Footer />
        </>
    )
}

export default Login;
