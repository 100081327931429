import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.scss';

import Home from './Mainpages/Auth/Home';
import About from './Mainpages/Auth/About';
import Contact from "./Mainpages/Auth/Contact";
import PricingPage from './Mainpages/Auth/PricingPage';
import Exams from './Mainpages/Auth/Exams';
import Login from './Mainpages/Auth/Login';
import ForgotPassword from './Mainpages/Auth/ForgotPassword';
import Signup from './Mainpages/Auth/Signup';

import Dashboard from './Mainpages/UserAdmin/Dashboard/index';
import ExaminationQuestion from './Mainpages/UserAdmin/ExaminationQuestion';
import Profile from './Mainpages/UserAdmin/Profile';
import ExamList from './Mainpages/UserAdmin/ExamList/index';
import Result from "./Mainpages/UserAdmin/Result";
import QuestionGrid from './Mainpages/UserAdmin/QuestionGrid';
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import DashboardLayout from "./component/Layouts/DashboardLayout";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

function App() {

  const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
  }


  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <RecoilRoot>
          <RecoilNexus />
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="pricing" element={<PricingPage />} />
              <Route path="exams" element={<Exams />} />
              <Route path="login" element={<Login />} />
              <Route path="forgotpass" element={<ForgotPassword />} />
              <Route path="signup" element={<Signup />} />

              <Route path="exam/" element={<DashboardLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="list" element={<ExamList />} />
                <Route path=":slug" element={<ExaminationQuestion />} />
                <Route path="result" element={<Result />} />
                <Route path="profile" element={<Profile />} />
              </Route>

             
              <Route path="grid" element={<QuestionGrid />} />
            </Routes>
          </Router>
        </RecoilRoot>
      </AlertProvider>
    </>
  );
}

export default App;


