import React from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import Button from 'react-bootstrap/Button';
import Header from '../../../component/Header';
 
import { Link } from 'react-router-dom';
import Footer from '../../../component/Footer';
import PageHeader from './../../../component/PageHeader/index';

const ForgotPassword = () => {
    return (
        <>
            <Header />

            <PageHeader
                image="images/login__page__header.webp"
                title="Forgot Password"
            />

            <div className="ptb">
                <Container>
                    <div className="auth__wrapp p-4 p-xl-5 pt-xl-4 mx-auto">
                        <h2 className="text-center">Forgot your password?</h2>
                        <p className='text-center mb-4 mb-xl-5'>
                            Enter your email address <br />
                            and recover your account 
                        </p> 

                        <Form>
                            <Form.Group className="mb-4" controlId="emailAddress">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email address" />
                            </Form.Group> 

                            <Form.Group className="mt-5 text-center">
                                <Button varient="primary" className='w-75 btn-lg fw-semibold'>RESET PASSWORD</Button>
                            </Form.Group> 
                        </Form>  
                    </div>
                </Container>
            </div>

            <Footer/>
        </>
    )
}

export default ForgotPassword;
