import React from 'react';

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import { GoBookmark } from 'react-icons/go';

import "./style.scss";

const HeroSlider = () => {
    return (
        <>
            <div className="hero_slider__wrapper">
                <Container fluid className='px-0'>
                    <Carousel variant="dark">
                        <Carousel.Item style={{background: `url(images/slider/hero_banner_1.webp)`}}> 
                            <Container className='d-flex align-items-center'> 
                                <div className='caption'>
                                    <p className='h1'>
                                        Get the Only PM Exam Simulator
                                        with the Largest Number of
                                        Realistic Sample Questions 
                                    </p>
                                    <p className='text-white h3'>Over 2,280+ exam-quality PMP® questions and explanations! </p>
                                    <ul className='list-unstyled ps-0 mb-0 text-white lead fw-normal'>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                    </ul>
                                </div>
                            </Container>
                        </Carousel.Item>
                        <Carousel.Item style={{background: `url(images/slider/hero_banner_2.webp)`}}> 
                            <Container className='d-flex align-items-center'> 
                                <div className='caption'>
                                    <p className='h1'>
                                        Get the Only PM Exam Simulator
                                        with the Largest Number of
                                        Realistic Sample Questions 
                                    </p>
                                    <p className='text-white h3'>Over 2,280+ exam-quality PMP® questions and explanations! </p>
                                    <ul className='list-unstyled ps-0 mb-0 text-white lead fw-normal'>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                        <li>
                                            <GoBookmark/>
                                            <span>Lorem ipsum dolor sit amet.</span>
                                        </li>
                                    </ul>
                                </div>
                            </Container>
                        </Carousel.Item>
                          
                    </Carousel> 
                </Container>
            </div>
        </>
    )
}
export default HeroSlider;
