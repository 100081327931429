

export default {
    courses:[
        {
            _id:'c1',
            cname: 'Machine Learning Management',
            image: 'images/course/machine-learning.webp',
            shortDesc: 'Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.',
            lessions: '12', 
            time: '126', 
            users: '25', 
            tags: [
                { _id:'t1', tname: 'PHP' },
                { _id:'t2', tname: 'Programming' } 
            ]
        }, 
        {
            _id:'c2',
            cname: 'Online Programming',
            image: 'images/course/online_programing.webp',
            shortDesc: 'Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.',
            lessions: '11', 
            time: '136', 
            users: '125', 
            tags: [
                { _id:'t1', tname: 'PHP' },
                { _id:'t2', tname: 'Programming' } 
            ]
        }, 
        {
            _id:'c3',
            cname: 'Actualized Leadership Network',
            image: 'images/course/leader_ship.webp',
            shortDesc: 'Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.',
            lessions: '08', 
            time: '116', 
            users: '115', 
            tags: [
                { _id:'t1', tname: 'PHP' },
                { _id:'t2', tname: 'Programming' } 
            ]
        }, 
        {
            _id:'c4',
            cname: 'Java Programming Masterclass',
            image: 'images/course/java_programing.webp',
            shortDesc: 'Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.',
            lessions: '08', 
            time: '116', 
            users: '115', 
            tags: [
                { _id:'t1', tname: 'JAVA' },
                { _id:'t2', tname: 'Programming' } 
            ]
        }, 
        {
            _id:'c5',
            cname: 'The Strategy of Content Marketing',
            image: 'images/course/strategy_content_marketing.webp',
            shortDesc: 'This course is a partnership between the leading content marketing authority, Copyblogger, and UC Davis Continuing and Professional Education. In this course, you will learn the core strategies content marketers use to acquire and retain customers profitably.',
            lessions: '08', 
            time: '116', 
            users: '115', 
            tags: [
                { _id:'t1', tname: 'Business' },
                { _id:'t2', tname: 'Marketing' } 
            ]
        }, 
    ]
}