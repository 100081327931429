import React, { useState, useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

const Clock = (expiryTimestamp) => {
    const {
        seconds,
        minutes,
        hours,
        // days,
        // isRunning,
        // start,
        // pause,
        // reset,
    } = useStopwatch({ autoStart: true });

    const formatTime = (time) => {
        return String(time).padStart(2, '0')
      }

    return (
        <>
            <div className='clock'>
                <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
            </div>
            <div> 
                {/* <p>{isRunning ? 'Running' : 'Not running'}</p> */}
                {/* <button onClick={start}>Start</button>
                <button onClick={pause}>Pause</button>
                <button onClick={reset}>Reset</button> */}
            </div>
        </>
    )
}

export default Clock;
