import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { useRecoilValue } from 'recoil';
import { examListState, tokenState } from '../../../utilit/RecoilState';
import { examListAction } from '../../../utilit/RecoilAction/AccountAction';
// import './style.scss';

const ExamList = () => {

    const token = useRecoilValue(tokenState)
    const exams = useRecoilValue(examListState)
    console.log(exams)
    useEffect(() => {
        token && examListAction(token)
    }, [])

    return (
        <div className="py-5 dashboard__wraper">
            <Container>
                <Card className='info__card exams'>
                    <Card.Body>
                        <h5 className='card-title'>Exams List</h5>

                        <table className='table data__table table-striped'>
                            <thead>
                                <th>Exam Name</th>
                                {/* <th>Total Question</th> */}
                                <th>Date</th>

                                <th>Correct Question</th>
                                <th>Status</th>
                                <th>Result</th>
                            </thead>
                            <tbody>
                                {exams?.map((exam, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/exam/${exam?.slug}`}>{exam?.title} ({exam?.test_question_count})</Link>
                                        </td>
                                        {/* <td>{exam?.test_question_count}</td> */}
                                        <td>10-04-2022</td>
                                        
                                        <td>{exam?.exam_user?.currect_answer}</td>
                                        <td><span className={`status ${exam?.exam_user?.status == 'C' ? '' : 'pending'}`}>{exam?.exam_user?.status == 'C' ? 'Complete' : 'Pending'}</span></td>
                                        <td>
                                            {exam?.exam_user?.status == 'C' ?
                                                <Link to={`/exam/result?slug=${exam?.slug}`} className='btn btn-primary'>View Result</Link> :
                                                <Link to={`/exam/${exam?.slug}`} className='btn btn-primary' >Start Naw</Link>
                                            }
                                        </td>
                                    </tr>)}
                                {/* <tr>
                                        <td><Link to='/examination'>Exam Name 2</Link></td>
                                        <td>10-04-2022</td>
                                        <td>33</td>
                                        <td>17</td>
                                        <td><span className='status pending'>Pending</span></td>
                                        <td>
                                            <Link to='/result' className='btn btn-primary'>View Result</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><Link to='/examination'>Exam Name 3</Link></td>
                                        <td>10-04-2022</td>
                                        <td>33</td>
                                        <td>17</td>
                                        <td><span className='status'>Complete</span></td>
                                        <td>
                                            <Link to='/result' className='btn btn-primary'>View Result</Link>
                                        </td>
                                    </tr> */}
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
            </Container>
        </div>

    )
}
export default ExamList;
