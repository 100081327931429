import React from 'react'
import Container from 'react-bootstrap/Container'; 
import Card from 'react-bootstrap/Card'; 
import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col'; 

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { HiOutlineBookmark } from "react-icons/hi";
import { AiOutlineClockCircle, AiOutlineUser } from "react-icons/ai";

import "./style.scss";


const options = {
    items: 3,
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    responsiveClass: true,
    autoplay:true, 
    autoplayHoverPause: true, 
    smartSpeed: 550,
    autoplayTimeout: 7000, 
    autoplaySpeed: 3000, 
    loop: true,
    responsive: {
        0: { items: 1 },
        576: { items: 1 },
        768: { items: 2 }, 
        992: { items: 2 }, 
        1400: { items: 3 }, 
    }
};


const Course = () => {
  return (
    <>
        <div className="ptb course__wrapper">
            <Container>
                <Row>
                    <Col lg={4} xxl={3}>
                        <p className='top__heading'>Courses and Specializations</p>
                        <h2 className='fw-bold'>Courses</h2>
                        <p className=''>
                            Student Registration and Administration Nemo enim 
                            ipsam voluptatem quia voluptas sit atur aut odit aut fugit, 
                            sed quia consequuntur magni res eos qui ratione 
                            voluptatem sequi nesciunt.
                        </p>  
                        <p>
                            There are many variations of passages of Lorem Ipsum 
                            available, but the majority have suffered alteration in 
                            some form, by injected humour, or randomised 
                            believable.
                        </p>
                    </Col>
                    <Col lg={8} xxl={9}> 
                        <OwlCarousel className='owl-theme courseCarousel' {...options}>
                            <div className='item'>
                                <Card className='course__card'>
                                    <Link to='/'>
                                        <img src="images/course/machine-learning.webp" className='img-fluid' alt="thumbnail" />
                                    </Link>
                                    <Card.Body>
                                        <h4 className='title'><Link to='/'>Machine Learning Management</Link></h4>
                                        <div className="cats">
                                            <Link to='/'>PHP</Link>
                                            <Link to='/'>Programming</Link>
                                        </div> 
                                    </Card.Body>
                                    <Card.Footer className='py-md- flex-wrap'>
                                        <div className='info__box'> 
                                            <HiOutlineBookmark/>
                                            <span>12 Lessions</span>
                                        </div> 
                                        <div className='info__box'> 
                                            <AiOutlineUser/>
                                            <span>Ram</span>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div> 
                            <div className='item'>
                                <Card className='course__card'>
                                    <Link to='/'>
                                        <img src="images/course/online_programing.webp" className='img-fluid' alt="thumbnail" />
                                    </Link>
                                    <Card.Body>
                                        <h4 className='title'><Link to='/'>Online Programming</Link></h4>
                                        <div className="cats">
                                            <Link to='/'>PHP</Link>
                                            <Link to='/'>Programming</Link>
                                        </div> 
                                    </Card.Body>
                                    <Card.Footer className='py-md- flex-wrap'>
                                        <div className='info__box'> 
                                            <HiOutlineBookmark/>
                                            <span>12 Lessions</span>
                                        </div> 
                                        <div className='info__box'> 
                                            <AiOutlineUser/>
                                            <span>245</span>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div> 
                            <div className='item'>
                                <Card className='course__card'>
                                    <Link to='/'>
                                        <img src="images/course/leader_ship.webp" className='img-fluid' alt="thumbnail" />
                                    </Link>
                                    <Card.Body>
                                        <h4 className='title'><Link to='/'>Actualized Leadership Network</Link></h4>
                                        <div className="cats">
                                            <Link to='/'>PHP</Link>
                                            <Link to='/'>Programming</Link>
                                        </div> 
                                    </Card.Body>
                                    <Card.Footer className='py-md- flex-wrap'>
                                        <div className='info__box'> 
                                            <HiOutlineBookmark/>
                                            <span>09 Lessions</span>
                                        </div> 
                                        <div className='info__box'> 
                                            <AiOutlineUser/>
                                            <span>2.45</span>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div> 
                            <div className='item'>
                                <Card className='course__card'>
                                    <Link to='/'>
                                        <img src="images/course/java_programing.webp" className='img-fluid' alt="thumbnail" />
                                    </Link>
                                    <Card.Body>
                                        <h4 className='title'><Link to='/'>Java Programming Masterclass</Link></h4>
                                        <div className="cats">
                                            <Link to='/'>PHP</Link>
                                            <Link to='/'>Programming</Link>
                                        </div> 
                                    </Card.Body>
                                    <Card.Footer className='py-md- flex-wrap'>
                                        <div className='info__box'> 
                                            <HiOutlineBookmark/>
                                            <span>09 Lessions</span>
                                        </div> 
                                        <div className='info__box'> 
                                            <AiOutlineUser/>
                                            <span>2.45</span>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div> 
                        </OwlCarousel>
                    </Col>
                </Row>
            </Container>    
        </div>   
    </>
  )
}

export default Course;
