import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'; 
import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col'; 

import Navigation from '../../../component/Navigation'; 
import './style.scss';
import { Card } from 'react-bootstrap';
import { BsClipboardCheck, BsExclamationCircle, BsArrowRepeat } from 'react-icons/bs';
import { AiOutlineCheckCircle } from 'react-icons/ai'; 

const Dashboard = () => {

  return (
   <main className='dashboard__wraper pt-5'>
          <Container>
            <Card>
              <Card.Body className='p-4'>
                <p className='lead fw-normal'>Welcome to PM Exam Simulator Dashboard</p>
                <Row className='g-4'>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Card className='info__card exams'>
                      <Card.Body className='align-items-center d-flex'>
                        <div className="icon">
                          <BsClipboardCheck/>
                        </div>
                        <div className="caption"> 
                          <p className="mb-0">Total Exams Completed</p>
                          <p className="h2 fw-semibold mb-0">3</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Card className='info__card correct'>
                      <Card.Body className='align-items-center d-flex'>
                        <div className="icon">
                          <AiOutlineCheckCircle/>
                        </div>
                        <div className="caption"> 
                          <p className="mb-0">Total Question Correct</p>
                          <p className="h2 fw-semibold mb-0">75</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Card className='info__card worng'>
                      <Card.Body className='align-items-center d-flex'>
                        <div className="icon">
                          <BsExclamationCircle/>
                        </div>
                        <div className="caption"> 
                          <p className="mb-0">Total Question Worng</p>
                          <p className="h2 fw-semibold mb-0">15</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6}>
                    <Card className='info__card retest'>
                      <Card.Body className='align-items-center d-flex'>
                        <div className="icon">
                          <BsArrowRepeat/>
                        </div>
                        <div className="caption"> 
                          <p className="mb-0">Total Retest Exams</p>
                          <p className="h2 fw-semibold mb-0">0</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

              </Card.Body>
            </Card>

            <Row className='g-4 mt-4'> 
              <Col lg={9} md={12} sm={12} xs={12}>
                <Card className='info__card exams'> 
                  <Card.Body>
                    <h5 className='card-title'>Result List</h5>
                     
                    <table className='table data__table table-striped'>
                      <thead>
                        <th>Exam Name</th>
                        <th>Date</th>
                        <th>Correct Question</th>
                        <th>Incorrect Question</th>
                        <th>Status</th>
                        <th>Result</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td><Link to='/examination'>Exam Name 1</Link></td>
                          <td>10-04-2022</td>
                          <td>33</td>
                          <td>17</td>
                          <td><span className='status'>Complete</span></td> 
                          <td>
                            <Link to='/result' className='btn btn-primary'>View Result</Link>
                          </td>
                        </tr>
                        <tr>
                          <td><Link to='/examination'>Exam Name 2</Link></td>
                          <td>10-04-2022</td>
                          <td>33</td>
                          <td>17</td>
                          <td><span className='status pending'>Pending</span></td>
                          <td>
                            <Link to='/result' className='btn btn-primary'>View Result</Link>
                          </td>
                        </tr>
                        <tr>
                          <td><Link to='/examination'>Exam Name 3</Link></td>
                          <td>10-04-2022</td>
                          <td>33</td>
                          <td>17</td>
                          <td><span className='status'>Complete</span></td>
                          <td>
                            <Link to='/result' className='btn btn-primary'>View Result</Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>  
                  </Card.Body>
                </Card>
              </Col> 
            </Row>



          </Container>
        </main>
  )
}

export default Dashboard;