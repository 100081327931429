import Cookies from 'js-cookie'

export const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue =  Cookies.get(key)

    if (savedValue != null) {
        setSelf(savedValue)
    }
    onSet((newValue) => {
         Cookies.set(key, newValue,{secure: true, expires: 365 })
    })
}