import React from 'react';

import Container from 'react-bootstrap/Container';
import './style.scss';

const PageHeader = ({image,title}) => {
  return (
    <>
        <div className="page__header" style={{backgroundImage: `url(${image})`}}>
            <Container> 
                <h2 className='text-center text-white py-4 fw-bold'>{title}</h2>
            </Container>
        </div>
    </>
  )
}

export default PageHeader;
