
import { setRecoil } from "recoil-nexus";
import { tokenState } from "../RecoilState/HomeState";
import axios from "../resources/axios";
import { ERROR_TEXT_API } from "../resources/staticData";




export const loginAction = async (values, actions,navigate,alert) => {
    axios({
        method: 'post',
        url: `login`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        data:values
    })
        .then(({ data }) => {
            if (data.status == "true") {
               setRecoil(tokenState,data?.token)
               alert.success(data?.message)
               navigate('/exam/dashboard')
            } else {
                alert.error(data?.message)
            }
            actions.setSubmitting(false)
        }).catch((error) => {
            
            actions.setSubmitting(false)
        })
}

export const forgetAction = async (values, setMsg, actions) => {

    axios({
        method: 'post',
        url: `auth/forgot-password`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        data: values

    })
        .then(({ data }) => {
            if (data.status == true) {
                setMsg({ status: true, color: 'green', msg: data.message })
                actions.resetForm()
            } else {
                setMsg({ status: true, color: 'red', msg: data.message })
            }
            setTimeout(() => setMsg({ status: false, color: '', msg: '' }), 8000)
        }).catch((error) => {
            console.log(error);
        })
}




