import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { HiOutlineBookmark } from "react-icons/hi";
import { AiOutlineClockCircle, AiOutlineUser } from "react-icons/ai";

import Header from '../../../component/Header/index';
import PageHeader from '../../../component/PageHeader/index';
import CourseInfo from '../../../component/Exams/CourseInfo';
import Footer from '../../../component/Footer';

const Courses = () => {
  return (
    <>
      <Header />
      <PageHeader
        image="images/course_page_header.webp"
        title="Exams"
      />

      <div className="ptb course__wrapper">
        <Container>
          <h2 className='text-center'>Courses</h2>
          <p className='text-center mx-auto col-lg-7 mb-4 mb-lg-5'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo rem nesciunt ex, quas odit, nisi, et voluptates maxime quidem placeat natus ullam nemo ab ducimus.
          </p>
          <Row className='g-4'>
            {
              CourseInfo.courses.map((course) => {
                return <Col lg={4} md={6} key={course._id}>
                  <Card className='course__card'>
                    <Link to='/'>
                      <img src={course.image} className='img-fluid' alt="thumbnail" />
                    </Link>
                    <Card.Body>
                      <h4 className='title'><Link to='/'>{course.cname}</Link></h4>
                      <div className="cats">
                        {
                          course.tags.map((tag) => {
                            return <Link to='/' key={tag._id}>{tag.tname}</Link>
                          })
                        } 
                      </div>
                      <p className='desc'>{course.shortDesc}</p>
                    </Card.Body>
                    <Card.Footer className='py-md-3 flex-wrap'>
                      <div className='info__box'>
                        <HiOutlineBookmark />
                        <span>{course.lessions} Lessions</span>
                      </div>
                      <div className='info__box'>
                        <AiOutlineClockCircle />
                        <span>{course.time}:00</span>
                      </div>
                      <div className='info__box'>
                        <AiOutlineUser />
                        <span>{course.users}</span>
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              })
            }
            
          </Row>
        </Container>
      </div>

      <Footer/>


    </>
  )
}

export default Courses;