import React from 'react';
import Header from '../../../component/Header';
import HeroSlider from './HeroSlider';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import {GiBrain, GiNotebook, GiClassicalKnowledge} from "react-icons/gi";
import { IoFlaskOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import Exams from '../../../component/Exams';
import Testimonials from '../../../component/Testimonials';
import UpcomingExams from '../../../component/UpcomingExams';
import Pricing from '../../../component/Pricing';
import Faq from '../../../component/Faq';
import Footer from '../../../component/Footer';

import './style.scss';
import { Link } from 'react-router-dom';
import ShortInfo from './ShortInfo';

const Home = () => {
  return (
    <>
      <Header />
      <HeroSlider />
      {/* <div className="header__wrapper bg-whit ptb">
        <Container>
          <Row className='g-4 align-items-center'>
            <Col lg={6} className='order-lg-2'>
              <img src="images/online_test.webp" className='img-fluid' alt="" />
            </Col>
            <Col lg={6} className='order-lg-1'>
              <h1 className='h1'>Learn without limits</h1>
              <p className='lead fw-normal'>
                Start, switch, or advance your career with more than 5,000 courses, Professional Certificates, and degrees from world-class universities and companies.
              </p>
              <div className="actions"> 
                <Link to='/' className='btn btn-primary fw-semibold me-2'>GET STARTED</Link>
                <Link to='/' className='btn btn-outline-primary fw-semibold'>LOG IN</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* ----- Header Bottom Wrapper Start ---------- */}
      <div className="py-5 short__info__wrapper text-center">
        <Container>
          <Row className='g-4'>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="content">
                <figure className="icon">
                  <GiBrain />
                </figure>
                <p className="mb-0 lead fw-medium text-dark">Best Stimulations</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="content">
                <figure className="icon">
                  <FaUsers />
                </figure>
                <p className="mb-0 lead fw-medium text-dark">Group Seminars</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="content">
                <figure className="icon">
                  <GiNotebook />
                </figure>
                <p className="mb-0 lead fw-medium text-dark">Analysed Syllabus</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="content">
                <figure className="icon">
                  <IoFlaskOutline/>
                </figure>
                <p className="mb-0 lead fw-medium text-dark">Pratical Training</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ----- Header Bottom Wrapper End ---------- */}


      {/* ----- Course Wrapper Start ---------- */}
      <Exams/>
      {/* ----- Course Wrapper End ---------- */}

      {/* ----- Short Info Wrapper Start ---------- */}
      <ShortInfo />
      {/* ----- Short Info Wrapper End ---------- */}

      {/* ----- Testimonial Wrapper Start ---------- */}
      <Testimonials />
      {/* ----- Testimonial Wrapper End ---------- */}

      {/* ----- Upcoming Exams Wrapper Start ---------- */}
      <UpcomingExams />
      {/* ----- Upcoming Exams Wrapper End ---------- */}
 
      {/* ----- Pricing Wrapper Start ---------- */}
      <Pricing />
      {/* ----- Pricing Wrapper End ---------- */}

      {/* ----- Faq Wrapper Start ---------- */}
      <Faq />
      {/* ----- Faq Wrapper End ---------- */}

      {/* ----- Footer Wrapper Start ---------- */}
      <Footer />
      {/* ----- Footer Wrapper End ---------- */}



    </>
  )
}

export default Home;
