import React from 'react';

import Navigation from '../../../component/Navigation';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'; 
import { AiOutlineUser, AiFillCheckCircle } from 'react-icons/ai';
import { BsCalendar2Check } from 'react-icons/bs';
import { IoRibbonOutline } from 'react-icons/io5';

import './style.scss';
import DetailedResult from './DetailedResult';

const Result = () => {
    return (
         <div className="py-5 dashboard__wraper">
                <Container>
                    <Card className='result__card border-0 shadow-sm bg-white p-3 p-md-4'>
                        <h3>PM Examination Report</h3>
                        <ul className='list-unstyled ps-0 result__data'>
                            <li className='lg_icon'>
                                <AiOutlineUser/>
                                <span className='small pe-2'>Candidate:</span>
                                <span className='fw-semibold'>Shuvrodeb Roy</span>
                            </li>
                            <li>
                                <BsCalendar2Check/>
                                <span className='small pe-2'>Exam Date:</span>
                                <span className='fw-semibold'>05-05-2022</span>
                            </li>
                            <li className='lg_icon'>
                                <IoRibbonOutline/>
                                <span className='small pe-2'>Your Exam Simulator Result:</span>
                                <span className='fw-semibold'>Pass <AiFillCheckCircle className='text-success'/></span>
                            </li>
                        </ul>

                        <h5 className='title text-primary text-underline mt-4 text-uppercase'>Summary Result:</h5>
                        <p>
                            You answered 5 out of 10 questions correctly - that is 50.00 % correct. Please note that this percentage is no gurantee that you will pass (or fail) your real PMI Exam. The total time for this exam was 00:05:30 (hh:mm:ss).
                        </p>

                        <h5 className='text-primary text-underline mt-4 text-uppercase'>Results By Domain:</h5>
                        <table className="table table-striped data__table">
                            <thead>
                                <tr> 
                                    <th>Domain Name</th>
                                    <th className='text-center'>No. of Questins</th>
                                    <th className='text-center'>Correct Answers</th>
                                    <th className='text-center'>% Correct</th>
                                    <th className='text-center'>Rating</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1. People</td>
                                    <td className='text-center'>5</td>
                                    <td className='text-center'>1</td>
                                    <td className='text-center'>20.00 %</td>
                                    <td className='text-center'>Needs Improvement</td>
                                </tr>
                                <tr>
                                    <td>2. Process</td>
                                    <td className='text-center'>5</td>
                                    <td className='text-center'>4</td>
                                    <td className='text-center'>80.00 %</td>
                                    <td className='text-center'>Above Target</td>
                                </tr>
                                <tr>
                                    <td>3. Business Enviroment</td>
                                    <td className='text-center'>0</td>
                                    <td className='text-center'>0</td>
                                    <td className='text-center'>0.00 %</td>
                                    <td className='text-center'>N/A</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>
                            A dialouge representation of your Performance ratings per domain has been provided above.
                        </p>
                        <h6 className='text-primary fw-semibold text-underline mt-4 text-uppercase'>Results By Domain:</h6>
                        <ul>
                            <li>
                                <b>Needs Imporvement:</b>
                                The number of questions you answered correctly is less than 50%. Your performance is far below the target and fails to meet the minimum requirements for this exam. Additional preparation is strongly recommended before re-examination.
                            </li>
                            <li>
                                <b>Below Target: </b>
                                The number of qustions you answered correctly is between 50% and 65%. Your performance is slightly below the target and fails to meet the minimum requirements for this exam. Additional preparation is recommended before re-examination.
                            </li>
                            <li>
                                <b>Target: </b>
                                The number of qustions you answered correctly is between 65% and 75%. Your performance meets the minimum requirements for this exam. 
                            </li>
                            <li>
                                <b>Above Target: </b>
                                The number of qustions you answered correctly is 75%. Your performance exceeds the minimum requirements for this exam. 
                            </li>
                        </ul>

                        <h5 className='text-primary text-underline mt-4 text-uppercase mb-4'>Detailed Results:</h5>

                        <DetailedResult />


                    </Card>
                </Container>
            </div>
    )
}

export default Result;