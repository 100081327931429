import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Header from '../../../component/Header';
import PageHeader from '../../../component/PageHeader'; 
import Footer from '../../../component/Footer';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaPinterest, FaYoutube, FaEnvelope } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai';
import { MdPhoneIphone } from 'react-icons/md';

import './style.scss';

const Contact = () => {
  return (
    <>
        <Header/>
        <PageHeader
            image="images/contact_page_header.webp" 
            title="Contact Us"
        />

        <div className="ptb contact__wrapper bg-light">
            <Container>
                <div className="form__wrapper p-4 p-lg-5"> 
                    <Row className='g-4'>
                        <Col lg={6}>
                            <p className='lead mb-1'>Get in touch</p>
                            <h3 className='title'>Write Us A Message</h3>

                            <p className='lead mb-1 mt-4'>Contact </p>
                            <ul className='list-unstyled ps-0'>
                                <li><AiTwotonePhone/> Land Line : 0484 2940532</li>
                                <li><MdPhoneIphone/> Mob : +91 9847055764</li>
                            </ul>

                            <p className='lead mb-1 mt-4'>Mail us</p>
                            <ul className='list-unstyled ps-0'>
                                <li><a href="mailto:info@example.com"><FaEnvelope/> info@example.com</a></li> 
                                <li><a href="mailto:example@gmail.com"><FaEnvelope /> example@gmail.com</a></li> 
                            </ul>

                            <p className='lead mb-1 mt-4'>Follow Us</p>
                            <ul className='list-unstyled ps-0 d-flex flex-wrap social__links'>
                                <li><a href="#" className='d-flex justify-content-center align-items-center rounded-circle'><FaFacebook/></a></li>  
                                <li><a href="#" className='d-flex justify-content-center align-items-center rounded-circle'><FaInstagram/></a></li>  
                                <li><a href="#" className='d-flex justify-content-center align-items-center rounded-circle'><FaLinkedinIn/></a></li>  
                                <li><a href="#" className='d-flex justify-content-center align-items-center rounded-circle'><FaPinterest/></a></li>  
                                <li><a href="#" className='d-flex justify-content-center align-items-center rounded-circle'><FaYoutube/></a></li>  
                            </ul>
                        </Col>
                        
                        <Col lg={6}>
                            <Form>
                                <Form.Group className="mb-3" controlId="userName">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control type="email" placeholder="Enter your name" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="emailAddress">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email address" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phoneNumber">
                                    <Form.Label>Your Phone Number</Form.Label>
                                    <Form.Control type="email" placeholder="Enter phone number" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Your Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
                                </Form.Group> 
                                <Form.Group className="text-end" controlId="message">
                                    <button className="btn btn-primary btn-lg">SUBMIT</button>
                                </Form.Group> 

                            </Form>
                        </Col>

                    </Row>
                </div>
            </Container>
        </div>

        <Footer/>

    </>
  )
}


export default Contact;