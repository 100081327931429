import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import Navigation from '../Navigation';
import { useRecoilValue} from 'recoil';
import { tokenState } from '../../utilit/RecoilState';


const DashboardLayout = () => {
    const token = useRecoilValue(tokenState)
    
    return (
        token ?
        <div>
            <Navigation />
            <Outlet />
        </div>:
        <Navigate to="/login" />
    )

}
export default DashboardLayout;