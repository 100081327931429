import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const ShortInfo = () => {
  return (
    <>
        <div className="ptb pt-0">
            <Container> 
                <Row className='g-4 justify-content-center'>
                    <Col lg={4} md={6}>
                        <Card className='info__card flex-row border-0 align-items-center'>
                            <figure className='mb-0 d-flex justify-content-center align-items-center p-2'>
                            <img src="images/icons/badge.png" className='img-fluid' alt="icon" />
                            </figure>
                            <h4 className='px-3 px-lg-4 h3 fw-normal mb-0 text-white'>Recognized By <br/> Govt of India</h4>
                        </Card>
                    </Col>
                    <Col lg={4} md={6}>
                        <Card className='info__card flex-row border-0 align-items-center'>
                            <figure className='mb-0 d-flex justify-content-center align-items-center p-2'>
                            <img src="images/icons/clock.png" className='img-fluid' alt="icon" />
                            </figure>
                            <h4 className='px-3 px-lg-4 h3 fw-normal mb-0 text-white'>Fast Service</h4>
                        </Card>
                    </Col>
                    <Col lg={4} md={6}>
                        <Card className='info__card flex-row border-0 align-items-center'>
                            <figure className='mb-0 d-flex justify-content-center align-items-center p-2'>
                            <img src="images/icons/user_sheild.png" className='img-fluid' alt="icon" />
                            </figure>
                            <h4 className='px-3 px-lg-4 h3 fw-normal mb-0 text-white'>Privacy</h4>
                        </Card>
                    </Col> 
                </Row>
            </Container>
        </div>
    </>
  )
}

export default ShortInfo;
