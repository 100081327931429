import React from 'react';

import Container from "react-bootstrap/Container";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { Link } from 'react-router-dom';
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';

import "./style.scss";
 
const options = {
    items: 2,
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    responsiveClass: true,
    autoplay:true, 
    autoplayHoverPause: true, 
    smartSpeed: 550,
    autoplayTimeout: 7000, 
    autoplaySpeed: 3000, 
    loop: true, 
    responsive: {
        0: { items: 1 },
        576: { items: 1 },
        768: { items: 1 }, 
        992: { items: 2 }, 
    }
};



const Exams = [
    {
        _id:'e1',
        thumbnail: "images/exam/exam_1.webp",
        exName: 'Machine Learning Test', 
    }, 
    {
        _id:'e2',
        thumbnail: "images/exam/exam_2.webp",
        exName: 'Learning Python for Data Analysis and Visualization', 
    }, 
    {
        _id:'e3',
        thumbnail: "images/exam/object_oriented_php.webp",
        exName: 'Object Oriented PHP & MVC', 
    }, 
]
 

const UpcomingExams = () => {
  return (
    <>
        <div className="ptb">
            <Container> 
                <h2 className="">Upcoming Exams</h2>
                <p className='mb-4 mb-lg-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit</p>

                <OwlCarousel className='owl-theme' {...options}> 
                    {
                        Exams.map((exam) => { 
                            return <div className="exam__card position-relative d-sm-flex align-items-center" key={exam._id}>
                            <Link to="/" className="text-center thumbnail">
                                <img src={exam.thumbnail} className='img-fluid google' alt='Google logo' />
                            </Link>  
                            <div className="caption">
                                <h4 className='h5 title'><Link to="/">{exam.exName}</Link></h4>
                                <div className="date">
                                    <AiOutlineCalendar /> <span className='pe-2'>Jul 2023</span> <AiOutlineClockCircle/> 08 00 am - <br/>
                                    <AiOutlineCalendar /> <span className="pe-2">Mar 2023</span> <AiOutlineClockCircle/> 10 00 pm
                                </div>
                                <div className="location d-flex align-items-center mt-2">
                                    <FiMapPin/>
                                    <span><Link to="/" className='ps-1'>Peppard Hill</Link>, United States,  <Link to="/">+ Google Map</Link> </span>
                                </div>
                            </div> 
                            
                        </div>
                        })
                    }
                    </OwlCarousel>
            </Container>    
        </div>   
    </>
  )
}

export default UpcomingExams;
