

export default {
    datalist:[
        {
            _id:'q1',
            qNo: '1',
            yourAns: 'A',
            correctAns: 'C',
            result: 'fail',
            domainName: '1. People', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:45',  
        }, 
        {
            _id:'q2',
            qNo: '2',
            yourAns: 'b',
            correctAns: 'C',
            result: 'fail',
            domainName: '1. People', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:35',  
        }, 
        {
            _id:'q3',
            qNo: '3',
            yourAns: 'C',
            correctAns: 'C',
            result: 'pass',
            domainName: '2. Process', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:35',  
        }, 
        {
            _id:'q4',
            qNo: '4',
            yourAns: 'B',
            correctAns: 'B',
            result: 'pass',
            domainName: '2. Process', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
        {
            _id:'q5',
            qNo: '5',
            yourAns: 'B',
            correctAns: 'A',
            result: 'fail',
            domainName: '1. People', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
        {
            _id:'q6',
            qNo: '6',
            yourAns: 'B',
            correctAns: 'A',
            result: 'fail',
            domainName: '1. People', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
        {
            _id:'q7',
            qNo: '7',
            yourAns: 'D',
            correctAns: 'D',
            result: 'pass',
            domainName: '2. Process', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
        {
            _id:'q8',
            qNo: '8',
            yourAns: 'D',
            correctAns: 'D',
            result: 'pass',
            domainName: '2. Process', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
        {
            _id:'q9',
            qNo: '9',
            yourAns: 'D',
            correctAns: 'D',
            result: 'pass',
            domainName: '2. Process', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
        {
            _id:'q10',
            qNo: '10',
            yourAns: 'A',
            correctAns: 'D',
            result: 'pass',
            domainName: '1. People', 
            knowledgeArea: 'Resource Management', 
            timeTaken: '0:55',  
        }, 
    ]
}