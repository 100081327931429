import React from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck'
import Button from 'react-bootstrap/Button';
import Header from '../../../component/Header';
 
import { Link } from 'react-router-dom';
import Footer from '../../../component/Footer';
import PageHeader from '../../../component/PageHeader';

const Signup = () => {
    return (
        <>
            <Header />

            <PageHeader
                image="images/login__page__header.webp"
                title="Sign Up"
            />

            <div className="ptb">
                <Container>
                    <div className="auth__wrapp p-4 p-xl-5 pt-xl-4 mx-auto">
                        <h2 className="text-center mb-4 mb-xl-5">Sign Up</h2>

                        <Form>
                            <Form.Group className="mb-4" controlId="Full Name">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="email" placeholder="Enter your name" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="emailAddress">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email address" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter password" />
                            </Form.Group> 
  
                            <Form.Group className="mt-5 text-center">
                                <Button varient="primary" className='w-75 btn-lg fw-semibold'>SIGNUP</Button>
                            </Form.Group> 
                        </Form>

                        <div className="text-center mt-4">
                            <span>Already have an account? </span>
                            <Link to='/login' className='link ps-2 fw-medium'>Login</Link>
                        </div>

                    </div>
                </Container>
            </div>

            <Footer/>
        </>
    )
}

export default Signup;
