
import Moment from 'moment';
import { tokenState } from '../RecoilState';
import { useRecoilValue } from 'recoil';


export const optionConv = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((m) => ({
        key: m.id,
       // flag:m.flag,
        text: m.name || m.status,
        value: m.country_code || m.isoCode,
    }));
};


export const answetCounter = (number)=>{
    if(number === 0){
        return 'A';
    }else if(number === 1){
        return 'B';
    }else if(number === 2){
        return 'C';
    }else if(number === 3){
        return 'D';
    }else if(number === 4){
        return 'E';
    }else if(number === 5){
        return 'F'
    }else if(number === 6){
        return 'G';
    }else if(number === 7){
        return 'H';
    }

}















export const phoneCodeConv = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((m) => ({
        key: m.id,
        //flag:m.flag,
        text: `${m.phonecode}` ,
        value: m.phonecode || m.phonecode,
    }));
};

export const StateConv = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((m) => ({
        key: m.id,
       // flag:m.flag,
        label: m.name || m.status,
        value: m.id,
    }));
};
export const cityConv = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((m) => ({
        key: m.id,
       // flag:m.flag,
       label: m.name || m.status,
        value: m.name,
    }));
};


export const objectToArray=(obj)=>{
    var result = [];
    if(obj){
        var result = Object.keys(obj).map(function(key) {
        return {"name":key,"value":obj[key]};
        }); 
    }
    return result; 
  }
  export const objectToQueryString=(obj)=>{
    var str = [];
    obj?.map((a)=>{
        if(a.name != 'category'){
        str.push(`${a.name}=${a.value}`);
        }
       
    })
    return  str.join("&");   
  }

