import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.scss';
import Clock from './Clock';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { examDetailsState, activeQuestionState, tokenState, questionCountState, qnsProgressState } from '../../../utilit/RecoilState';
import { answerSaveAction, examDetailsAction } from '../../../utilit/RecoilAction/AccountAction';
import { setRecoil } from "recoil-nexus";
import { answetCounter } from '../../../utilit/resources/helpers';
import * as yup from 'yup';
import { Formik } from "formik";
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";

const ExaminationQuestion = () => {

    const { slug } = useParams()
    const navigate = useNavigate();
    const token = useRecoilValue(tokenState)
    const examDetails = useRecoilValue(examDetailsState)
    const activeQuestion = useRecoilValue(activeQuestionState);
    const qnsCount = useRecoilValue(questionCountState);
    const qnsProgress = useRecoilValue(qnsProgressState)
    const [hintData, setHintData] = useState(false);
   
    let questionAnswer = yup.object().shape({
        answer: yup.string()
            .required('Plase seelct your answer')
    });
    const hintModalHandleShow = (question) => setHintData(question)
       
    const qnsProgressFun = () =>{
        if(qnsCount){
            setRecoil(qnsProgressState,100/qnsCount*activeQuestion)
        }
    }

    console.log("qnscount ", examDetails)
    console.log("activeQuestion ", activeQuestion)

    const time = new Date();
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    //const hintModalHandleClose = () => setHintModal(false);
    

    useEffect(() => {
        slug && examDetailsAction(token, slug)
    }, [slug])

    return (
        <>
            <div className="py-5 dashboard__wraper">
                <Container>
                    <Row className='g-4'>
                        <Col md={8}>
                            <div className="progress__wrapp bg-white d-flex shadow-sm rounded">
                                <div className="progress__box">
                                    <p className='pro__title'>Question Progrees</p>
                                    <div className="progress"><span className="active" style={{ width: `${qnsProgress}%` }}></span></div>
                                </div>
                                <div className="time">
                                    <p className='mb-1'>Exam Hours</p>
                                    <Clock expiryTimestamp={time} />
                                </div>
                            </div>
                            {examDetails?.test_question?.map((question, index) =>
                                activeQuestion == parseFloat(index+1) &&
                                <div className="question__wrapp p-4 shadow-sm rounded" key={index}>
                                    <div className="action__btns d-flex justify-content-end mb-2">
                                       {qnsCount == activeQuestion  ? '' : <Button onClick={()=> {
                                            setRecoil(activeQuestionState,parseFloat(activeQuestion+1))
                                            qnsProgressFun()
                                            }} variant='secondary'>UNMARED</Button>}
                                        <Button variant='primary' onClick={()=>hintModalHandleShow(question)}>Hind</Button>
                                        {/* <Link to='/grid' className='btn btn-primary'>SHOW GRID</Link> */}
                                    </div>
                                    <h5>Q {index + 1}: {question?.question_info?.question}</h5>
                                    <Formik
                                        initialValues={{
                                            test_id:examDetails?.id,
                                            question_id:question?.question_info?.id,
                                            answer: '',
                                            end_exam:qnsCount == activeQuestion ? 'yes':'',
                                        }}
                                        enableReinitialize={true}
                                        validationSchema={questionAnswer}
                                        onSubmit={(values, actions) => {
                                            qnsProgressFun()
                                            answerSaveAction(values, actions, token,activeQuestion,navigate,slug)
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue
                                        }) => (
                                            <Form className='form__wrapp' onSubmit={handleSubmit}>
                                                {question?.question_info?.answer_info?.map((answer, index2) =>
                                                    <Form.Check type='radio' id={`ans${index}`} key={index2}>
                                                        <FormCheck.Input onChange={(e)=>setFieldValue('answer',e.target.value)} value={answer.id} type='radio' name='answer' />
                                                        <FormCheck.Label>{answetCounter(index2)}. {answer.answer}</FormCheck.Label>
                                                    </Form.Check>
                                                )}
                                                {errors.answer && touched.answer && <p style={{ color: "red",marginLeft: "23px" }}>{errors.answer}</p>}
                                                <div className="text-center btns d-flex justify-content-between">
                                                    <Button variant='danger' onClick={handleShow}>END EXAM</Button>
                                                    <div>
                                                        <Button disabled={activeQuestion == 1 ? true :false} onClick={()=>{
                                                            setRecoil(activeQuestionState,parseFloat(activeQuestion-1))
                                                            qnsProgressFun()
                                                        }} variant='primary'>PREV</Button>
                                                        {qnsCount == activeQuestion  ? 
                                                        <Button type='submit' disabled={isSubmitting ? true : false} variant='primary'>{isSubmitting ? 'Loading...' :'COMPLETE'}</Button>
                                                        :<Button type='submit' disabled={isSubmitting ? true : false} variant='primary'>{isSubmitting ? 'Loading...' :'NEXT'}</Button>
                                                        }
                                                        </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            )}

                        </Col>

                        <Col md={4}>
                            <div className="bg-white p-4 overview__wrapp text-center shadow-sm rounded">
                                <p>OVERVIEW</p>

                                <div className="info__box text-center rounded-circle d-inline-flex justify-content-center align-items-center">
                                    <div>
                                        <div className='h3 mb-0 text-white'>{examDetails?.test_question?.length}</div>
                                        <p className="mb-0 text-white">Total Question</p>
                                    </div>
                                </div>
                                <div className="info__box correct text-center rounded-circle d-inline-flex justify-content-center align-items-center">
                                    <div>
                                        <div className='h3 mb-0 text-white'>22</div>
                                        <p className="mb-0 text-white">Correct</p>
                                    </div>
                                </div>
                                <div className="info__box worng text-center rounded-circle d-inline-flex justify-content-center align-items-center">
                                    <div>
                                        <div className='h3 mb-0 text-white'>08</div>
                                        <p className="mb-0 text-white">Worng</p>
                                    </div>
                                </div>
                                <div className="info__box pending text-center rounded-circle d-inline-flex justify-content-center align-items-center">
                                    <div>
                                        <div className='h3 mb-0 text-white'>20</div>
                                        <p className="mb-0 text-white">Pending</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* ======= Exam End Warning Modal ======= */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{examDetails?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>You have marked/unanswered question. Are you sure you want to end the exam?</h5>
                    <p className='text-secondary mt-3'>
                        In order to see unanswered questions, go back and in grid mode, select the "Unanswered" filter. Marked questions are indicated in grid mode.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Go Back
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        End Exam
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* ======= Exam End Warning Modal ======= */}
            <Modal show={hintData ? true : false} onHide={()=>setHintData(false)} className='hintModal'>
                <Modal.Header closeButton>
                    <Modal.Title>Question Hint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className='text-dark fw-bold'>Q :{hintData?.question_info?.question}</h6>
                    <div className='text-secondary mt-3 ps-3'>
                      {hintData &&  parse(hintData?.question_info?.hins)}
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ExaminationQuestion;
