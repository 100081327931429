import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { VscQuote } from "react-icons/vsc";
import ReactStars from 'react-stars'

import "./style.scss";
 
const options = {
    items: 1,
    loop: true,
    margin: 20,
    nav: false,
    dots: false,
    responsiveClass: true,
    autoplay:true, 
    autoplayHoverPause: true, 
    smartSpeed: 550,
    autoplayTimeout: 7000, 
    autoplaySpeed: 3000, 
    loop: true, 
};



const Reviews = [
    {
        _id:1,
        testiText: 'I have decided to go with something different and I  went with The PM PrepCast Exam Simulatoe and I belive that is the best decision. I am very glad that I did that. After all out time ismore valuable than the money.',
        stuName: 'Vishnu Katta', 
        arating: 5
    },
    {
        _id:2,
        testiText: 'I have decided to go with something different and I  went with The PM PrepCast Exam Simulatoe and I belive that is the best decision. I am very glad that I did that. After all out time ismore valuable than the money.',
        stuName: 'Shuvro Deb', 
        arating: 5
    },
    {
        _id:3,
        testiText: 'I have decided to go with something different and I  went with The PM PrepCast Exam Simulatoe and I belive that is the best decision. I am very glad that I did that. After all out time ismore valuable than the money.',
        stuName: 'Amrita Deb', 
        arating: 4.5
    },
]
 

const Testimonials = () => {
  return (
    <>
        <div className="ptb testimonial__wrapper bg-white" style={{backgroundImage:'url(images/Testimonial_Background.webp)',boxShadow: 'rgba(204, 203, 203, 0.85) 0px 0px 0px 4000px inset', backgroundAttachment:'fixed'}}>
            <Container>
                <Row className='g-4'>
                    <Col lg={6}>
                        <h2 className='h3 after'>Choosing The PrepCast PM  Exam Simulator was the best  decision</h2>
                        <p className='fw-normal'>
                            Reviewing without practice isn’t enough to help improve your exam-taking confidence. PM knowledge, and time management skills during the actual exam.
                        </p>
                        <p className='fw-normal'>
                            Reviewing without practice isn’t enough to help improve your exam-taking confidence.
                        </p>
                    </Col>
                    <Col lg={6}>
                        <OwlCarousel className='owl-theme' {...options}> 
                        {
                            Reviews.map((review) => { 
                                return <div className="testimonial__items position-relative" key={review._id}>
                                <div className="quote position-absolute top-0 start-0"><VscQuote/></div> 
                                <p className="testi__text">
                                    {review.testiText}
                                </p>
                                <div className="student__thumbnail h4 mx-auto">V</div>
                                <p className="h4">{review.stuName}</p>
                                <div className="text-center">
                                    <img src="images/icons/Google_logo.png" className='img-fluid google' alt='Google logo' />
                                </div>
                                <div className="ratings d-flex justify-content-center"> 
                                    <ReactStars
                                        count={5}
                                        value={review.arating}
                                        isHalf={true} 
                                        size={24}
                                        edit= {false}
                                        activeColor="#fbbe01"
                                    />
                                </div>
                            </div>
                            })
                        }
                        </OwlCarousel>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
  )
}

export default Testimonials;