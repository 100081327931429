import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom';
import { AiOutlineCalendar, AiFillInstagram } from 'react-icons/ai';
import { BiBook, BiPhoneCall } from 'react-icons/bi';
import { MdKeyboardArrowRight, MdOutlineMailOutline } from 'react-icons/md'; 
import { FiMapPin } from 'react-icons/fi';
import { FaFacebook, FaTwitter, FaPinterest } from 'react-icons/fa';

import './style.scss';

const Footer = () => {
  return (
    <>
        <footer>
            <Container>
                <Row className='gy-4 gx-4'>
                    <Col xl='3' lg='4' md='6'>
                        <h6 className='title lead'>About us</h6>
                        <p className='fs-13'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, fuga minus. Sunt illum, a obcaecati corrupti dolore veniam vitae velit magni eveniet sit ipsam. Ipsam?
                        </p>
                        <Link to='/' className='btn btn-outline-dark'> Start Learning Now </Link>
                    </Col>    
                    <Col xl='3' lg='4' md='6'>
                        <h6 className='title lead'>Popular Courses</h6>
                        <ul className='course__list list-unstyled ps-0'>
                            <li className='d-flex align-items-center'>
                                <Link to='/' className='thumbnail'>
                                    <img src="images/course/machine-learning.webp" className='img-fluid' alt="thumbnail" />
                                </Link>
                                <div className="caption">
                                    <Link to='/' className='course__title lh-1'>Subjects allied to Creative arts and design</Link>
                                    <div className="d-flex info fs-12">
                                        <div className="date me-2"><AiOutlineCalendar/> 12 Feb, 2018</div>
                                        <div className="lession"><BiBook/> 5 Lessons </div>
                                    </div>
                                </div>
                            </li>
                            <li className='d-flex align-items-center'>
                                <Link to='/' className='thumbnail'>
                                    <img src="images/course/machine-learning.webp" className='img-fluid' alt="thumbnail" />
                                </Link>
                                <div className="caption">
                                    <Link to='/' className='course__title lh-1'>Subjects allied to Creative arts and design</Link>
                                    <div className="d-flex info fs-12">
                                        <div className="date me-2"><AiOutlineCalendar/> 12 Feb, 2018</div>
                                        <div className="lession"><BiBook/> 5 Lessons </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Col>    
                    <Col xl='3' lg='4' md='6'>
                        <h6 className='title lead'>Quick Links</h6>
                        <ul className='link__list list-unstyled ps-0'>
                            <li>
                                <Link to='/exams' className='thumbnail'><MdKeyboardArrowRight/> All Exams </Link> 
                            </li> 
                            <li>
                                <Link to='/' className='thumbnail'><MdKeyboardArrowRight/> Summer Sessions</Link> 
                            </li> 
                            <li>
                                <Link to='/' className='thumbnail'><MdKeyboardArrowRight/> Summer Sessions</Link> 
                            </li> 
                            <li>
                                <Link to='/' className='thumbnail'><MdKeyboardArrowRight/> Summer Sessions</Link> 
                            </li> 
                            <li>
                                <Link to='/' className='thumbnail'><MdKeyboardArrowRight/> Summer Sessions</Link> 
                            </li> 
                        </ul>
                    </Col>    
                    <Col xl='3' lg='6' md='6'>
                        <h6 className='title lead'>Contact us</h6>
                        <ul className='link__list address list-unstyled ps-0'>
                            <li>
                                <div className='d-flex location'>
                                    <FiMapPin /> 
                                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span>
                                </div> 
                            </li> 
                            <li>
                                <a href='tel:+910000000000' className='d-flex align-items-center'>
                                    <BiPhoneCall/> 
                                    <span>+910000000000</span>
                                </a> 
                            </li> 
                            <li>
                                <a href='mailto:info@gmail.com' className='d-flex align-items-center'>
                                    <MdOutlineMailOutline/> 
                                    <span>info@gmail.com</span>
                                </a> 
                            </li>  
                        </ul>
                    </Col>    
                </Row>    
            </Container>  

            <div className="py-3 mt-3 mt-lg-4 copy__right">
                <Container className='d-md-flex justify-content-between align-items-center'>
                    <ul className='list-unstyled d-flex mb-0 social__links order-md-2 justify-content-center mb-3 mb-md-0'>
                        <li>
                            <a href="#"><FaFacebook/></a>
                        </li>    
                        <li className='mx-2'>
                            <a href="#"><FaTwitter/></a>
                        </li>    
                        <li className='me-2'>
                            <a href="#"><AiFillInstagram/></a>
                        </li>    
                        <li>
                            <a href="#"><FaPinterest/></a>
                        </li>    
                    </ul>   
                    <div className="fs-13 text-white order-md-1 text-center text-md-start">
                        Copyright © 2022 PM Exam All Rights Reserved | Website by <a href="#" target='_blank' className='developed_by'>Sova Computer</a>    
                    </div>  
                </Container>  
            </div>
        </footer>   
    </> 
  )
}

export default Footer;
