import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import { AiFillCloseCircle } from 'react-icons/ai';

import './style.scss';

const QuestionModal = ({modalName, close }) => {
    const [selected, setSelected] = useState('yes');

    const handleChange = event => {
        console.log(event.target.value);
        setSelected(event.target.value);
    };
    return (
        <>
            <Modal show={modalName} onHide={close} className="question__details__modal">
                <Modal.Header closeButton>
                    <Modal.Title>Question Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='position-relative'>
                    <div className="small position-absolute bg-light px-1 rounded" style={{color:'#aaa',right:'1rem',top:'0.25rem'}}>Question ID: 003456</div>
                    <div className="text-dark fw-semibold">Question No: 01</div>

                    <div className="bg-light p-2 border rounded my-3 fs-6" >
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur perspiciatis, quidem optio obcaecati quasi expedita perferendis possimus nemo eaque ea, aliquam vero!
                    </div>

                    <Form className='form__wrapp ps-3'>
                        <Form.Check type='radio' id="ans1">
                            <span className='fw-semibold'>A.</span>
                            <FormCheck.Input type='radio' name='answer' 
                                checked={selected === 'no'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>The Project manager</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='radio' id="ans2">
                            <span className='fw-semibold'>B.</span>
                            <FormCheck.Input type='radio' name='answer'  
                                checked={selected === 'no'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>The developement team</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='radio' id="ans3">
                            <span className='fw-semibold'>C.</span>
                            <FormCheck.Input type='radio' name='answer' 
                                checked={selected === 'yes'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>The scrum master</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='radio' id="ans4">
                            <span className='fw-semibold'>D.</span>
                            <FormCheck.Input type='radio' name='answer' 
                                checked={selected === 'no'}
                                onChange={handleChange}
                                disabled
                            />
                            <FormCheck.Label>the product owner</FormCheck.Label>
                        </Form.Check> 
                    </Form>
                    <p className='ps-3 fw-semibold my-3'> 
                        <AiFillCloseCircle className='text-danger svg'/> Question answered incorrectly.
                    </p>

                    <div className="bg-light p-3 border rounded">
                        <h5 className='mb-0'>Hint</h5>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati at officiis vero quisquam perspiciatis totam?</p>

                        <p className='text-success fw-semibold lead'>Correct Answer: B</p>

                        <h5 className=''>Explanation:</h5>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae molestiae tempore reprehenderit nihil cumque ex nulla hic amet impedit fuga! Sint in voluptates dolores odio magnam, labore nihil, harum eligendi voluptatum quisquam aut, adipisci expedita asperiores dolor. Qui blanditiis ullam quisquam ipsa molestiae quasi ab.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae molestiae tempore reprehenderit nihil cumque ex nulla hic amet impedit fuga! Sint in voluptates dolores odio magnam, labore nihil, harum eligendi voluptatum quisquam aut, adipisci expedita asperiores dolor. Qui blanditiis ullam quisquam ipsa molestiae quasi ab.
                        </p>

                        <h5 className='mt-3 mt-lg-4'>Details for Each Option:</h5>
                        <div className='option__details'>
                            <Form.Check type='radio' id="ans1">
                                <span className='fw-semibold'>A.</span>
                                <FormCheck.Input type='radio' name='answer' 
                                    checked={selected === 'no'}
                                    onChange={handleChange}
                                    disabled
                                />
                                <FormCheck.Label>The Project manager</FormCheck.Label>
                            </Form.Check>
                            <p className="desc">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora deserunt aliquid ad. Iusto, cum ea non illo delectus accusamus ut labore aliquid aut id. Quam. 
                            </p>
                        </div>
                        <div className='option__details'>
                            <Form.Check type='radio' id="ans2">
                                <span className='fw-semibold'>B.</span>
                                <FormCheck.Input type='radio' name='answer'  
                                    checked={selected === 'no'}
                                    onChange={handleChange}
                                    disabled
                                />
                                <FormCheck.Label>The developement team</FormCheck.Label>
                            </Form.Check>
                            <p className="desc">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos dolores eveniet tenetur laboriosam nisi dolor maxime aperiam, ipsum mollitia animi id repellendus harum quia, tempore est beatae nostrum consequatur culpa neque dicta. Soluta, saepe laboriosam.
                            </p>
                        </div>
                        <div className='option__details'>
                            <Form.Check type='radio' id="ans3">
                                <span className='fw-semibold'>C.</span>
                                <FormCheck.Input type='radio' name='answer' 
                                    checked={selected === 'yes'}
                                    onChange={handleChange}
                                    disabled
                                />
                                <FormCheck.Label>The scrum master</FormCheck.Label>
                            </Form.Check>
                            <p className="desc">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, repudiandae? Obcaecati iste sit vero necessitatibus fugit cumque illo et aliquid?
                            </p>
                        </div>
                        <div className='option__details'>
                            <Form.Check type='radio' id="ans4">
                                <span className='fw-semibold'>D.</span>
                                <FormCheck.Input type='radio' name='answer' 
                                    checked={selected === 'no'}
                                    onChange={handleChange}
                                    disabled
                                />
                                <FormCheck.Label>the product owner</FormCheck.Label>
                            </Form.Check> 
                            <p className="desc">
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem, repudiandae? Obcaecati iste sit vero necessitatibus fugit cumque illo et aliquid?
                            </p>
                        </div>

                        <h5 className='mt-3 mt-lg-4'>Reference:</h5>
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam debitis in temporibus sint tenetur quas. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam debitis in temporibus sint tenetur quas.
                        </p>

                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={close}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default QuestionModal;
