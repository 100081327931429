export default {
    questions: [
        { id: '1',ans: 'A', marked: 'No' },
        { id: '2',ans: 'C', marked: 'Yes' },
        { id: '3',ans: 'B', marked: 'Yes' },
        { id: '4',ans: 'B', marked: 'No' },
        { id: '5',ans: 'D', marked: 'No' },
        { id: '6',ans: 'D', marked: 'Yes' },
        { id: '7',ans: 'D', marked: 'No' },
        { id: '8',ans: 'B', marked: 'No' },
        { id: '9',ans: 'A', marked: 'No' },
        { id: '10',ans: 'C', marked: 'No' },
        { id: '11',ans: 'C', marked: 'No' },
        { id: '12',ans: 'B', marked: 'No' },
        { id: '13',ans: 'D', marked: 'No' },
        { id: '14',ans: 'B', marked: 'No' },
        { id: '15',ans: 'D', marked: 'No' },
        { id: '16',ans: 'A', marked: 'Yes' },
        { id: '17',ans: 'A', marked: 'No' },
        { id: '18',ans: 'c', marked: 'No' },
        { id: '19',ans: 'c', marked: 'No' },
        { id: '20',ans: 'C', marked: 'No' },
        { id: '21',ans: 'B', marked: 'No' },
        { id: '22',ans: 'B', marked: 'No' },
        { id: '23',ans: 'B', marked: 'Yes' },
        { id: '24',ans: 'D', marked: 'No' },
        { id: '25',ans: 'B', marked: 'No' },
        { id: '26',ans: 'B', marked: 'No' },
        { id: '27',ans: 'B', marked: 'No' },
        { id: '28',ans: 'A', marked: 'No' },
        { id: '29',ans: 'B', marked: 'No' },
        { id: '30',ans: 'B', marked: 'No' },
        { id: '31',ans: 'B', marked: 'No' },
        { id: '32',ans: 'C', marked: 'No' },
        { id: '33',ans: 'D', marked: 'No' },
        { id: '34',ans: 'D', marked: 'No' },
        { id: '35',ans: 'B', marked: 'No' },
        { id: '36',ans: 'B', marked: 'Yes' },
        { id: '37',ans: 'A', marked: 'No' },
        { id: '38',ans: 'B', marked: 'No' },
        { id: '39',ans: 'B', marked: 'No' },
        { id: '40',ans: 'D', marked: 'No' },
        { id: '41',ans: 'B', marked: 'No' },
        { id: '42',ans: 'C', marked: 'No' },
        { id: '43',ans: 'B', marked: 'No' },
        { id: '44',ans: 'B', marked: 'Yes' },
        { id: '45',ans: 'D', marked: 'No' },
        { id: '46',ans: 'B', marked: 'No' },
        { id: '47',ans: 'A', marked: 'No' },
        { id: '48',ans: 'B', marked: 'No' },
        { id: '49',ans: 'B', marked: 'No' },
        { id: '50',ans: 'D', marked: 'Yes' }
    ]
}