import React, { useState } from 'react';

import Card from 'react-bootstrap/Card'; 
import Form from 'react-bootstrap/Form'; 
import FormCheck from 'react-bootstrap/FormCheck'; 
import Button from 'react-bootstrap/Button'; 
import Collapse from 'react-bootstrap/Collapse';
import { FiFilter } from 'react-icons/fi';



// import './style.scss';

const Filter = () => {
    const [genFilter, setGenFilter] = useState(true);
    const [domainFilter, setDomainFilter] = useState(false);
    const [knowledgeFilter, setKnowledgeFilter] = useState(false);
    return (
        <>
            <Card>
                <Card.Header
                    onClick={() => setGenFilter(!genFilter)}
                    aria-controls="example-collapse-text"
                    aria-expanded={genFilter}
                >
                    <Card.Title className='mb-0'>General Filter</Card.Title>
                </Card.Header>
                <Collapse in={genFilter}>
                    <Card.Body> 
                        <Form.Check type='checkbox' id="all">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>All</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="answered">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Answered</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="marked">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Marked</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="strikethrough">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Strikethrough</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="correctly">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Answered correctly</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="incorrectly">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Answered incorrectly</FormCheck.Label>
                        </Form.Check>
                    </Card.Body> 
                </Collapse>
            </Card>

            <Card className='mt-3'>
                <Card.Header
                    onClick={() => setDomainFilter(!domainFilter)}
                    aria-controls="example-collapse-text"
                    aria-expanded={domainFilter}
                >
                    <Card.Title className='mb-0'>Domain Filter</Card.Title>
                </Card.Header>
                <Collapse in={domainFilter}>
                    <Card.Body> 
                        <Form.Check type='checkbox' id="all">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>All</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="onePeople">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>1 People</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="process">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>2 Process</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="bEnviroment">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>3 Business Enviroment</FormCheck.Label>
                        </Form.Check> 
                    </Card.Body> 
                </Collapse>
            </Card>

            <Card className='mt-3'>
                <Card.Header
                    onClick={() => setKnowledgeFilter(!knowledgeFilter)}
                    aria-controls="example-collapse-text"
                    aria-expanded={knowledgeFilter}
                >
                    <Card.Title className='mb-0'>Knowledge Area Filter</Card.Title>
                </Card.Header>
                <Collapse in={knowledgeFilter}> 
                    <Card.Body> 
                        <Form.Check type='checkbox' id="all">
                            <FormCheck.Input type='checkbox'/>
                            <FormCheck.Label>All</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="integration">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Integration</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="scope">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Scope</FormCheck.Label>
                        </Form.Check>
                        <Form.Check type='checkbox' id="schedule">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Schedule</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="cost">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Cost</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="quality">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Quality</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="resource">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Resource</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="communication">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Communication</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="risk">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Risk</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="procurement">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Procurement</FormCheck.Label>
                        </Form.Check> 
                        <Form.Check type='checkbox' id="stakeholder">
                            <FormCheck.Input type='checkbox' />
                            <FormCheck.Label>Stakeholder</FormCheck.Label>
                        </Form.Check> 
                    </Card.Body>
                </Collapse>
            </Card>

            <div className="mt-3 text-center">
                <Button variant="danger"><FiFilter/> Clear All Filter</Button>
            </div>
        </>
    )
}

export default Filter;
